@charset "UTF-8";
button:focus, button:focus {
  outline: none; }

html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth; }

body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 29px;
  color: #191C2B;
  padding: 0;
  margin: 0;
  box-sizing: border-box; }
  body.over_h {
    overflow: hidden; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Scada", sans-serif;
  font-weight: 700;
  width: 100%; }

.h2 {
  margin-bottom: 30px; }

header {
  padding: 29px 0 34px; }

a {
  color: #191C2B;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }

* {
  outline: none; }

a:hover {
  text-decoration: none;
  color: #F5A100; }

.separator {
  width: 100%;
  height: 4px;
  background-color: #F2F6F6;
  margin: 50px 0; }

header.white {
  position: relative; }
  header.white .main_menu_block a {
    color: #191C2B; }

.lang_block:after {
  border-top-color: #191C2B; }

.main_menu_block a {
  color: #191C2B; }

.main_menu_block ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.main_menu_block a {
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none;
  display: block; }

.main_menu_block li:hover a {
  animation: menu_hover 250ms linear 0s 1 forwards; }

.main_menu_block li.active a {
  color: #F5A100; }

.lang_block {
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  cursor: pointer; }
  .lang_block.open ul {
    display: block;
    position: absolute;
    width: 37px;
    text-align: center;
    border: 1px solid #999; }
  .lang_block ul {
    display: none; }

.lang_block:after {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  border-top: 8px solid #191C2B;
  top: 10px;
  left: 25px; }

.lang_block.open:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 5px; }

.header_scroll_wrapp {
  display: none; }
  .header_scroll_wrapp .scrolled_menu {
    position: fixed;
    top: 28px;
    width: 111px;
    background-color: #191C2B;
    padding: 10px 20px 12px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    transition: all 0.5s ease-out; }
    .header_scroll_wrapp .scrolled_menu.active {
      background-color: #fff; }
      .header_scroll_wrapp .scrolled_menu.active .hamburger_inner {
        transform: rotateY(180deg);
        background-color: transparent; }
      .header_scroll_wrapp .scrolled_menu.active .hamburger_inner::after {
        transform: translate3d(0, -5px, 0) rotate(-45deg);
        background-color: #191C2B; }
      .header_scroll_wrapp .scrolled_menu.active .hamburger_inner::before {
        transform: translate3d(0, 5px, 0) rotate(45deg);
        background-color: #191C2B; }

.hamburger {
  float: right;
  background-color: transparent;
  border: none;
  padding: 0; }
  .hamburger .hamburger_wrapp {
    width: 24px;
    height: 12px;
    display: block;
    position: relative; }

.hamburger_inner, .hamburger_inner::after, .hamburger_inner::before {
  width: 24px;
  display: block;
  height: 2px;
  background-color: #fff;
  position: absolute;
  transition: all 0.5s ease-out; }

.hamburger_inner::after, .hamburger_inner::before {
  content: ''; }

.hamburger_inner {
  top: 50%; }

.hamburger_inner::before {
  top: -5px; }

.hamburger_inner::after {
  bottom: -5px; }

.full_menu_wrapp {
  transform: translateY(-100%);
  background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform .5s ease-in;
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 750px;
  z-index: 9; }
  .full_menu_wrapp.open {
    transform: none; }
  .full_menu_wrapp ul {
    padding: 0;
    list-style: none;
    font-size: 20px;
    line-height: 50px; }
  .full_menu_wrapp a {
    text-decoration: none;
    color: #fff;
    transition: all 0.25s ease-out; }
  .full_menu_wrapp li.active a, .full_menu_wrapp a:hover {
    color: #191C2B; }
  .full_menu_wrapp .full_menu, .full_menu_wrapp .full_title {
    font-family: "Scada", sans-serif;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    text-transform: uppercase; }
    .full_menu_wrapp .full_menu ul, .full_menu_wrapp .full_title ul {
      font-size: 40px; }

.full_title:after {
  content: '';
  display: block;
  width: 35px;
  height: 2px;
  background-color: #fff;
  margin: 30px 0; }

.social_block {
  max-width: 253px;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.full_menu li {
  margin-bottom: 20px; }

.contact_block .fa {
  margin-right: 10px; }

.full_contacts .contact_block {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 27px; }
  .full_contacts .contact_block span {
    display: block; }

.full_lang_block {
  font-size: 20px;
  padding-top: 5px; }
  .full_lang_block a {
    display: inline-block;
    padding: 16px;
    width: 61px;
    text-align: center; }
    .full_lang_block a.current {
      background-color: #fff;
      border-radius: 50%;
      color: #191C2B; }

.all-select-filter {
  display: flex;
  padding-top: 80px;
  flex-wrap: wrap;
  align-items: flex-start; }
  .all-select-filter .check-filter {
    box-sizing: border-box;
    border-radius: 28px;
    padding: 12px 45px 12px 35px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    line-height: 180%;
    color: #7E8888;
    border: 1px solid #7E8888;
    position: relative;
    margin: 0 7px;
    margin-bottom: 15px; }
    .all-select-filter .check-filter img {
      padding-left: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
      opacity: 0.7; }
      .all-select-filter .check-filter img:hover {
        opacity: 1; }

.filter-portfolio_container {
  transform: translateY(-110%);
  transition: transform 0.5s ease-in-out;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #E5E5E5;
  display: flex;
  align-items: center; }
  .filter-portfolio_container.active {
    transform: translateY(0); }
  .filter-portfolio_container .container {
    position: relative; }
    .filter-portfolio_container .container .headline:after {
      margin: 35px 0;
      content: '';
      display: block;
      width: 35px;
      height: 2px;
      background-color: #191C2B; }
    .filter-portfolio_container .container .close {
      position: fixed;
      top: 50px;
      cursor: pointer; }
  .filter-portfolio_container .filter-form {
    display: flex;
    flex-wrap: wrap; }
    .filter-portfolio_container .filter-form .filter-submit {
      width: 100%;
      margin-top: 50px; }
    .filter-portfolio_container .filter-form .type-filter {
      min-width: 350px; }
      .filter-portfolio_container .filter-form .type-filter .title {
        font-weight: bold;
        font-size: 24px;
        font-family: "Scada", sans-serif;
        line-height: 30px;
        margin-bottom: 30px; }
      .filter-portfolio_container .filter-form .type-filter .check-input input {
        display: none; }
        .filter-portfolio_container .filter-form .type-filter .check-input input:checked + label:before {
          background-color: #F5A100; }
        .filter-portfolio_container .filter-form .type-filter .check-input input:checked + label span {
          opacity: 1; }
      .filter-portfolio_container .filter-form .type-filter .check-input label {
        padding-left: 30px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer; }
        .filter-portfolio_container .filter-form .type-filter .check-input label span {
          position: absolute;
          left: 3px;
          top: 30%;
          display: flex;
          align-items: center;
          color: #fff;
          opacity: 0; }
          .filter-portfolio_container .filter-form .type-filter .check-input label span i {
            font-size: 12px; }
        .filter-portfolio_container .filter-form .type-filter .check-input label:before {
          content: '';
          border: 1px solid #F5A100;
          box-sizing: border-box;
          border-radius: 2px;
          display: inline-block;
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0; }

.full_menu_bottom {
  align-items: center; }

.content {
  padding: 100px 0; }
  .content .contact_block {
    margin-top: 40px; }
    .content .contact_block span {
      display: block;
      margin-top: 10px; }
    .content .contact_block p {
      font-size: 24px;
      font-family: "Scada", sans-serif; }

.form_default {
  margin-top: 30px; }
  .form_default input, .form_default textarea {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #191C2B;
    transition: border-color .15s ease-in-out;
    resize: none;
    padding: 6px 0 12px;
    color: #191C2B; }
    .form_default input:focus, .form_default textarea:focus {
      box-shadow: none;
      border-color: #F5A100; }
  .form_default input::placeholder, .form_default textarea::placeholder {
    color: #191C2B; }

input[type="file"] {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

input[type="file"] + label {
  height: 38px;
  display: block;
  color: #191C2B;
  border-bottom: 2px solid #191C2B;
  margin: 0;
  cursor: pointer; }
  input[type="file"] + label:hover {
    border-color: #F5A100; }
  input[type="file"] + label span {
    display: flex;
    align-items: center;
    width: 100%; }
    input[type="file"] + label span .fa {
      position: absolute;
      right: 15px; }

.submit_btn {
  font-family: "Scada", sans-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin-top: 30px; }
  .submit_btn:focus {
    box-shadow: none; }
  .submit_btn:after {
    content: '';
    width: 41%;
    height: 3px;
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
    display: block;
    transition: width .4s; }
  .submit_btn:hover:after {
    width: 87%; }
  .submit_btn:hover .fa {
    transform: translateX(10px); }
  .submit_btn .fa {
    transition: transform .5s;
    transition-delay: .3s;
    padding-left: 5px; }

.map_block {
  padding-bottom: 523px; }

footer {
  background: #1E2132;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 19px; }
  footer a {
    color: #fff; }
  footer .social_block {
    float: right; }
  footer .footer_top_wrapp {
    align-items: center; }

.footer_top {
  padding: 49px 0 75px; }

.footer_contacts {
  display: flex;
  justify-content: space-between; }
  .footer_contacts span {
    display: block; }
  .footer_contacts .contact_block {
    max-width: 158px; }

.through_header header {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
  z-index: 9; }
  .through_header header a, .through_header header li {
    color: #fff; }
  .through_header header .lang_block:after {
    border-top-color: #fff; }

.through_header .content {
  padding-top: 0; }

.home_video {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #000;
  z-index: 0; }
  .home_video video {
    position: absolute;
    min-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    margin: 0 auto; }

.video_content_block {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%; }

.video_txt {
  font-size: 40px;
  line-height: 50px; }
  .video_txt h1 {
    font-weight: bold;
    font-size: 56px;
    line-height: 62px;
    text-transform: uppercase;
    margin-bottom: 50px; }

.video_contacts {
  position: absolute;
  display: flex;
  bottom: 10%;
  color: #A0A5B8;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px; }
  .video_contacts .contact_block span {
    display: inline-block;
    margin-right: 10px; }
    .video_contacts .contact_block span .fa {
      margin-right: 4px; }
  .video_contacts a {
    color: #A0A5B8; }
    .video_contacts a:hover {
      color: #F5A100; }

.switch_txt {
  opacity: 0;
  position: absolute;
  transition: 0.3s ease-in-out;
  transform: translateY(20%); }
  .switch_txt.active {
    opacity: 1;
    transform: translateY(0); }

/*Блок с анимацией*/
.home_services {
  padding: 35px 0;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden; }

.slick-track {
  display: flex;
  align-items: center; }

.services_wrapp .slick-slide {
  margin: 0 15px; }

.services_wrapp .slick-slide:first-child {
  margin-left: 0; }

.services_static {
  max-width: 460px;
  transition: all 0.5s ease-in; }
  .services_static .second_title {
    opacity: 1;
    transform: translateY(0); }

.main-container {
  max-width: 600px;
  height: 2000px;
  margin: 0 auto;
  border: solid 10px green;
  padding: 10px;
  margin-top: 40px;
  position: relative; }

.main-container * {
  padding: 10px;
  background: #aaa;
  border: dashed 5px #000; }

.main-container * + * {
  margin-top: 20px; }

.main-header {
  height: 50px;
  background: #aaa;
  border-color: red; }

.main-content {
  min-height: 1000px; }

.main-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.horizontal-scroll {
  position: relative; }

.horizontal-scroll-counter {
  font-size: 24px;
  line-height: 30px;
  font-family: "Scada", sans-serif;
  text-align: right;
  color: #F5A100;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0; }

.horizontal-scroll-skip {
  position: absolute;
  right: 0;
  bottom: -90px;
  color: #7E8888;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-decoration-line: underline; }

.services_static {
  overflow: hidden;
  position: relative;
  padding-top: 180px; }
  .services_static.active .first_title {
    transform: translateY(-100%);
    opacity: 0; }
  .services_static.active .second_title {
    transform: translateY(0);
    display: block;
    opacity: 1; }
  .services_static.active p {
    transform: translateX(-100%);
    opacity: 0; }
  .services_static .h2 {
    margin-bottom: 70px;
    position: absolute;
    top: 0; }
  .services_static .first_title {
    transition: all 0.5s; }
  .services_static .second_title {
    transform: translateY(-100%);
    opacity: 1;
    transition: all 1s; }
  .services_static p {
    transition: all 0.3s ease-in-out; }

.horizontal-scene {
  position: sticky;
  top: 0; }
  .horizontal-scene .container {
    position: relative; }

.horizontal-scroll-body {
  position: absolute;
  padding-left: 120%;
  bottom: 20px;
  white-space: nowrap; }

.slick-active .services_static {
  transform: translateY(0);
  opacity: 1;
  transition: all 2s ease-in-out; }
  .slick-active .services_static .second_title {
    transform: translateY(-100%);
    opacity: 0;
    transition: all 2s ease-in-out; }

a.service_item {
  white-space: normal;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  padding: 64px 15px 0;
  width: 460px;
  min-height: 350px;
  color: #191C2B;
  background-color: #F2F6F6;
  overflow: hidden;
  position: relative; }
  a.service_item.active {
    color: #fff; }
    a.service_item.active .hover-bg {
      transform: translateY(0); }
  a.service_item .hover-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    transform: translateY(101%);
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%); }
  a.service_item ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 1; }
    a.service_item ul li {
      font-size: 20px;
      margin-bottom: 15px; }
  a.service_item div {
    font-family: "Scada", sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin: 15px 0;
    position: relative;
    z-index: 1; }
  a.service_item p {
    z-index: 1; }

.slick-active + .slick-active a.service_item {
  background-color: #F5A100;
  color: #fff; }
  .slick-active + .slick-active a.service_item div {
    margin-top: 0; }

/*Блок с анимацией конец*/
.home_offers {
  padding: 35px 0; }

.offer_item {
  position: relative;
  margin: 15px 0;
  width: 50%;
  padding-right: 15px;
  max-height: 217px;
  overflow: hidden; }
  .offer_item:nth-child(even) {
    padding-left: 15px; }
  .offer_item.white {
    color: #fff; }
  .offer_item.last {
    width: 100%;
    padding-left: 15px;
    padding-right: 0;
    max-height: 464px;
    min-height: 404px; }
  .offer_item img {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .offer_item video {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .offer_item div {
    position: absolute;
    top: 32px;
    left: 40px;
    font-size: 20px;
    line-height: 27px;
    max-width: 260px; }

.global_partners {
  padding: 35px 0; }

.partners_wrapp {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0; }

.partner_item {
  margin: 10px;
  width: 14%;
  text-align: center; }
  .partner_item img {
    max-width: 70%; }

.show_more_link a {
  font-size: 20px;
  text-decoration: underline;
  font-weight: 700;
  line-height: 25px;
  color: #7E8888; }
  .show_more_link a:hover {
    text-decoration: none;
    color: #F5A100; }

.global_portfolio {
  padding: 35px 0; }

.global_portfolio .container {
  padding: 0; }

.portfolieo_item {
  position: relative;
  overflow: hidden;
  margin: 27px 0;
  transition: all .4s; }
  .portfolieo_item .overlay {
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(240, 244, 250, 0.57) 0%, rgba(240, 244, 250, 0.7) 68.75%, rgba(255, 255, 255, 0) 100%); }
  .portfolieo_item a {
    display: block; }
  .portfolieo_item img {
    width: 100%;
    transition: all .6s ease-in-out; }
  .portfolieo_item a {
    z-index: 9; }
  .portfolieo_item span {
    display: block; }
  .portfolieo_item .tags_block {
    transform: translateY(100%);
    opacity: 0;
    transition: all .5s ease-in;
    display: flex; }
  .portfolieo_item:hover .tags_block {
    transform: translateY(0);
    opacity: 1; }
  .portfolieo_item:hover .portfolio_txt {
    top: 30px; }
  .portfolieo_item:hover img {
    transform: scale(1.1); }
  .portfolieo_item:hover .overlay {
    opacity: 1; }

.portfolio_txt {
  position: absolute;
  width: 100%;
  top: 60px;
  text-align: center;
  z-index: 2;
  text-transform: uppercase;
  transition: all .5s ease-in; }
  .portfolio_txt > span {
    color: #7E8888;
    font-size: 12px;
    font-weight: 600; }
  .portfolio_txt .title {
    font-family: "Scada", sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #000; }

.tags_block {
  display: flex;
  justify-content: center;
  max-width: 70%;
  margin: 10px auto 0;
  flex-wrap: wrap; }
  .tags_block span {
    display: block;
    border: 1px solid #000;
    border-radius: 16px;
    padding: 0 25px;
    box-sizing: border-box;
    margin: 10px 5px;
    color: #191C2B;
    font-size: 11px;
    font-weight: 600; }

.portfolieo_item.last_link {
  height: 100%;
  max-height: 589px;
  display: flex;
  align-items: center;
  border: 3px solid #ADC8DB; }
  .portfolieo_item.last_link .portfolio_txt {
    position: static; }
  .portfolieo_item.last_link div {
    margin-top: 35px;
    font-size: 12px;
    font-weight: 600; }
  .portfolieo_item.last_link a {
    text-decoration: underline; }
    .portfolieo_item.last_link a:hover {
      text-decoration: none; }

.home_quote {
  padding: 130px 0; }

.quote_left {
  position: relative; }

.quote_icon {
  position: absolute;
  left: -30px;
  top: -30px;
  z-index: -9; }

.quote_img {
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  margin-top: 50px; }
  .quote_img img {
    width: 80px;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    width: 104px; }
  .quote_img div + div {
    padding-left: 15px; }

.quote_right {
  max-height: 340px;
  overflow-y: scroll; }
  .quote_right::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    padding: 0 2px; }
  .quote_right::-webkit-scrollbar-button {
    display: none; }
  .quote_right::-webkit-scrollbar-track {
    width: 1px;
    background-color: #887D6B; }
  .quote_right::-webkit-scrollbar-track-piece {
    background: linear-gradient(to right, #fff 30%, #887D6B 50%, #fff 70%); }
  .quote_right::-webkit-scrollbar-thumb {
    width: 6px;
    height: 50px;
    background-color: #887D6B; }

.footer_form {
  padding: 125px 0; }
  .footer_form input, .footer_form textarea {
    border-bottom: 2px solid #fff;
    transition: border-color .15s ease-in-out;
    padding: 6px 0 12px;
    color: #fff; }
    .footer_form input:focus, .footer_form input :active, .footer_form textarea:focus, .footer_form textarea :active {
      box-shadow: none;
      border-color: #F5A100;
      background-color: transparent;
      color: #fff; }
  .footer_form input::placeholder, .footer_form textarea::placeholder {
    color: #fff; }
  .footer_form input[type="file"] + label {
    height: 38px;
    display: block;
    color: #fff;
    border-bottom: 2px solid #fff; }
  .footer_form .submit_btn {
    color: #fff; }
  .footer_form .form_default {
    margin-top: 0; }

@keyframes menu_hover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  50% {
    -webkit-transform: translateY(0.938rem);
    transform: translateY(0.938rem);
    opacity: 0; }
  51% {
    -webkit-transform: translateY(-0.938rem);
    transform: translateY(-0.938rem);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    color: #F5A100; } }

.portfolio_header {
  padding-top: 108px;
  padding-bottom: 20px; }

.first-block_text .pre-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 180%;
  text-transform: uppercase;
  padding-left: 4px; }

.first-block_text .title {
  font-weight: bold;
  font-size: 56px;
  line-height: 95.92%;
  text-transform: uppercase;
  font-family: "Scada", sans-serif;
  margin-bottom: 70px; }

.first-block_text p {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  font-family: "Scada", sans-serif;
  color: #7E8888;
  margin-bottom: 20px; }

.first-block_text .yellow-btn {
  display: inline-block; }

.yellow-btn {
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 11px;
  line-height: 180%;
  background: #F5A100;
  border: 1px solid #F5A100;
  box-sizing: border-box;
  border-radius: 28px;
  padding: 12px 35px;
  transition: all 0.3s ease-in-out; }
  .yellow-btn:hover {
    background-color: #1E2132;
    color: #fff;
    border: 1px solid #1E2132; }

.breadcrumbs ul {
  margin: 20px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }
  .breadcrumbs ul li {
    margin-right: 35px;
    list-style: none;
    position: relative;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    color: #7E8888; }
    .breadcrumbs ul li:last-child {
      opacity: 0.6; }
      .breadcrumbs ul li:last-child:after {
        display: none; }
    .breadcrumbs ul li:after {
      content: '';
      width: 13px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      right: -30px;
      display: inline-block;
      height: 4px;
      background-image: url(../images/breadarr.svg);
      background-size: cover; }

.service_page {
  padding-top: 70px;
  padding-bottom: 130px; }

.service_row {
  margin-top: 50px; }

.service_page .service_item {
  margin-bottom: 38px;
  background: #F2F6F6;
  padding: 20px 50px 50px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  height: 288px;
  flex-direction: column;
  justify-content: flex-end;
  position: relative; }
  .service_page .service_item:hover div {
    color: #fff; }
    .service_page .service_item:hover div img {
      transform: translate(0);
      bottom: unset;
      opacity: 1; }
  .service_page .service_item:hover span {
    transform: translateY(0); }
  .service_page .service_item:hover p {
    color: #fff; }
  .service_page .service_item span {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%); }
  .service_page .service_item div {
    font-weight: bold;
    font-size: 24px;
    z-index: 1;
    line-height: 30px;
    font-family: "Scada", sans-serif;
    margin-bottom: 15px;
    position: relative;
    transition: all 0.3s ease-in-out; }
    .service_page .service_item div img {
      position: absolute;
      right: 0px;
      transform: translate(-20px, 20px);
      top: 10px;
      opacity: 0;
      transition: all 0.4s ease-in-out; }
  .service_page .service_item p {
    position: relative;
    z-index: 1;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #191C2B; }

.headline {
  font-family: "Scada", sans-serif;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #191C2B;
  margin-bottom: 35px; }

.image-block1 {
  height: 270px;
  margin-bottom: 30px; }
  .image-block1 img {
    width: 100%;
    object-fit: cover;
    height: 100%; }

.content-info ul, .portfolio-project_container ul {
  padding-left: 18px; }
  .content-info ul li, .portfolio-project_container ul li {
    color: #F5A100;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%; }
    .content-info ul li span, .portfolio-project_container ul li span {
      color: #191C2B; }

.content-info h1, .portfolio-project_container h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  font-family: "Scada", sans-serif;
  margin-bottom: 20px; }

.content-info h2, .portfolio-project_container h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px; }

.content-info .border-top, .portfolio-project_container .border-top {
  padding-top: 20px;
  border-top: 1px solid rgba(51, 51, 51, 0.3); }

.content-info .two-col-block, .portfolio-project_container .two-col-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(51, 51, 51, 0.3); }
  .content-info .two-col-block.no-border, .portfolio-project_container .two-col-block.no-border {
    border-top: 0; }
  .content-info .two-col-block div, .portfolio-project_container .two-col-block div {
    width: 22%;
    padding-right: 15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px; }
  .content-info .two-col-block p, .portfolio-project_container .two-col-block p {
    width: 75%;
    margin-bottom: 0; }

.capture-block {
  background: #F2F6F6;
  min-height: 235px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px; }
  .capture-block div {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 28px; }
  .capture-block .yellow-btn {
    padding: 12px 55px; }

.yellow-number {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #F5A100;
  padding-right: 10px; }

.quote {
  padding-left: 60px;
  margin: 50px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #191C2B;
  font-family: "Scada", sans-serif;
  position: relative;
  padding-top: 25px; }
  .quote img {
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: -1; }
  .quote span {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #7E8888;
    display: block;
    margin-top: 15px;
    font-family: "Open Sans", sans-serif; }

.news-item {
  margin-bottom: 50px; }
  .news-item .news-img {
    text-align: center; }
    .news-item .news-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .news-item .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 110%;
    font-family: "Scada", sans-serif;
    color: #333;
    margin: 15px 0 20px; }
  .news-item p {
    font-size: 16px;
    line-height: 180%;
    color: #4B4B4B; }

.mr-20 {
  margin: 20px 0; }

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden; }
  .tags span {
    display: block;
    border: 1px solid #666;
    border-radius: 16px;
    padding: 0 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10px 0;
    color: #666;
    font-size: 11px;
    font-weight: 600;
    margin-right: 10px; }

.horizontal-news.left .news-img {
  float: left;
  margin-right: 30px; }

.horizontal-news.right .news-img {
  margin-left: 30px;
  float: right; }

.horizontal-news .news-img {
  width: 65%;
  height: 425px; }

.horizontal-news .tags {
  margin-bottom: 20px; }

.pagination-block {
  text-align: center; }
  .pagination-block .prev, .pagination-block .next {
    font-size: 14px; }
  .pagination-block ul {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin: 30px 0; }
    .pagination-block ul li {
      list-style: none;
      margin: 0 25px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-transform: uppercase; }

.pb-30 {
  padding-bottom: 30px; }

.more-btn {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  font-family: "Scada", sans-serif;
  color: #191C2B;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out; }
  .more-btn:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
    display: inline-block;
    height: 3px;
    width: 50%;
    transition: width 0.3s ease-in-out; }
  .more-btn:hover:after {
    width: 100%; }

.message-btn {
  position: fixed;
  right: 30px;
  bottom: 50px; }
  .message-btn .start-btn {
    width: 56px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #9EDF49;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05); }
    .message-btn .start-btn span {
      display: none; }
    .message-btn .start-btn:after {
      content: '';
      position: absolute;
      border: 1px solid #9EDF49;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: widgetPulse infinite 1.5s; }
  .message-btn .green-circle {
    width: 56px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #9EDF49;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease-in-out; }
  .message-btn.active .circle1 {
    top: -80px; }
  .message-btn.active .circle2 {
    top: -160px; }
  .message-btn.active .start-btn {
    background: #F2F6F6;
    transform: rotate(180deg); }
    .message-btn.active .start-btn > img {
      display: none; }
    .message-btn.active .start-btn span {
      display: block; }
    .message-btn.active .start-btn:after {
      display: none;
      animation: none; }

@keyframes widgetPulse {
  50% {
    transform: scale(1, 1);
    opacity: 1; }
  100% {
    transform: scale(2, 2);
    opacity: 0; } }

.duo-photo {
  margin-bottom: 30px; }
  .duo-photo img {
    width: 100%;
    height: 300px; }
  .duo-photo p {
    margin-top: 1px;
    font-weight: 600;
    font-size: 16px;
    line-height: 170.23%; }

.final-news {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.23%;
  letter-spacing: 0.05em;
  color: #666666; }

.separator-2 {
  background: #F2F6F6;
  height: 4px;
  margin-bottom: 40px; }

.portfolio-poster_top {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center; }
  .portfolio-poster_top .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #191C2B;
    opacity: 0.6;
    z-index: 1; }
  .portfolio-poster_top .container {
    height: 100vh;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    color: #fff; }
    .portfolio-poster_top .container .tags span {
      color: #fff;
      border: 1px solid #fff; }

.portfolio-project_container {
  padding: 100px 0; }

.video-poster {
  margin: 50px 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .video-poster a {
    width: 270px;
    transition: transform 0.3s ease-in-out; }
    .video-poster a:hover {
      transform: scale(1.05); }
    .video-poster a img {
      width: 100%; }

.pair-photo {
  text-align: right;
  position: relative;
  margin-bottom: 60px; }
  .pair-photo .small {
    position: absolute;
    top: 15%;
    left: 0;
    z-index: 10; }
  .pair-photo .big {
    position: relative;
    z-index: 9; }

.simple-img-wrapper {
  margin-bottom: 30px; }
  .simple-img-wrapper img {
    width: 100%; }

.portfolio_slider {
  margin-bottom: 80px; }

.multiple-items {
  background: #F2F6F6;
  padding: 40px 0; }
  .multiple-items .slide-item {
    transform: scale(0.95);
    opacity: 0.2;
    padding: 0 15px;
    transition: all 0.3s ease-in-out; }
    .multiple-items .slide-item.slick-active {
      opacity: 1;
      transform: scale(1); }
    .multiple-items .slide-item img {
      width: 100%; }

.navigation-slider-port {
  position: relative;
  display: flex;
  width: 50%;
  top: -18px;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto; }
  .navigation-slider-port .counter-slide {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%; }
    .navigation-slider-port .counter-slide .current-slide {
      font-family: "Scada", sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #191C2B; }
    .navigation-slider-port .counter-slide .all-slide {
      padding-right: 30px; }

#portfolioNav {
  position: relative;
  display: flex;
  padding: 0 15px; }
  #portfolioNav .slick-arrow {
    width: 170px;
    height: 125px;
    margin-right: 12px;
    padding: 0;
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0;
    background: #191C2B;
    border: none; }

.portfolio-footer-nav {
  background-image: url("../images/bg-portff.png");
  background-size: cover;
  background-position: center;
  padding: 60px 0; }
  .portfolio-footer-nav .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .portfolio-footer-nav .container > div {
      width: 40%; }
      .portfolio-footer-nav .container > div.center-nav {
        width: 20%; }
        .portfolio-footer-nav .container > div.center-nav a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          opacity: 0.3;
          transition: all 0.2s ease-in-out; }
          .portfolio-footer-nav .container > div.center-nav a:hover {
            opacity: 1;
            color: #191C2B; }
          .portfolio-footer-nav .container > div.center-nav a img {
            padding-bottom: 8px; }
  .portfolio-footer-nav .skip-project.left:hover img {
    transform: translateX(-10px); }
  .portfolio-footer-nav .skip-project.left img {
    transition: transform 0.2s ease-in-out;
    padding-right: 30px; }
  .portfolio-footer-nav .skip-project.right {
    display: flex;
    justify-content: flex-end;
    text-align: right; }
    .portfolio-footer-nav .skip-project.right:hover img {
      transition: transform 0.2s ease-in-out;
      transform: translateX(10px); }
    .portfolio-footer-nav .skip-project.right img {
      padding-left: 30px; }
  .portfolio-footer-nav .skip-project a {
    display: flex;
    align-items: center; }
    .portfolio-footer-nav .skip-project a .nav-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: #7E8888; }
      .portfolio-footer-nav .skip-project a .nav-text span {
        display: block;
        margin-top: 8px;
        color: #191C2B;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        font-family: "Scada", sans-serif; }

.error404 {
  text-align: center;
  min-height: 70vh; }
  .error404 .title {
    font-weight: bold;
    font-size: 56px;
    line-height: 95.92%;
    font-family: "Scada", sans-serif;
    text-transform: uppercase;
    color: #191C2B;
    margin-bottom: 35px; }
  .error404 p a {
    background-color: rgba(245, 161, 0, 0.2);
    display: inline-block;
    text-decoration: underline; }

.capture_form .footer_form .submit_btn {
  color: #191C2B; }
  .capture_form .footer_form .submit_btn i {
    color: #191C2B; }

.capture_form .footer_form input, .capture_form .footer_form textarea {
  color: #191C2B;
  border-bottom: 2px solid #191C2B; }
  .capture_form .footer_form input::-webkit-input-placeholder, .capture_form .footer_form textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #191C2B; }
  .capture_form .footer_form input::-moz-placeholder, .capture_form .footer_form textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #191C2B; }
  .capture_form .footer_form input:-ms-input-placeholder, .capture_form .footer_form textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #191C2B; }
  .capture_form .footer_form input:-moz-placeholder, .capture_form .footer_form textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #191C2B; }

.communication_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }
  .communication_buttons .social a {
    display: inline-block;
    margin-right: 15px; }

.our_values .services_static {
  padding-top: 110px; }

.our_values a.service_item {
  width: 360px;
  min-height: 290px;
  height: 290px;
  overflow: hidden;
  position: relative;
  padding: 0; }
  .our_values a.service_item.active .open-photo {
    opacity: 1; }
  .our_values a.service_item .open-photo {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0;
    transition: opacity 0.3s; }
  .our_values a.service_item > img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.history_company .services_static {
  padding-top: 140px; }

.history_company a.service_item {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 64px 15px 0 64px; }

.about-header-scroll .first-block_text {
  max-width: 58%;
  overflow: hidden;
  position: relative; }
  .about-header-scroll .first-block_text.active .title, .about-header-scroll .first-block_text.active .pre-title {
    transform: translateY(-150%);
    transition: transform 1s; }
  .about-header-scroll .first-block_text.active p {
    transform: translateX(-150%);
    transition: transform 0.7s; }

.about-header-scroll a.service_item {
  height: 700px;
  width: auto;
  background-color: inherit; }

.about-header-scroll .horizontal-scroll-body {
  padding-left: 58%;
  top: -70%; }

.job-list_header {
  padding: 70px 0 60px; }

.mb-50 {
  margin-bottom: 50px; }

.achievements {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 80px; }
  .achievements .achievements-item {
    cursor: pointer;
    padding: 54px 0;
    width: 28%;
    text-align: center;
    margin-bottom: 20px;
    transition: background-color 0.3s ease-in-out; }
    .achievements .achievements-item:hover {
      background: #F2F6F6; }
    .achievements .achievements-item img {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 35px; }
    .achievements .achievements-item p {
      color: #000; }

.team-section {
  padding-bottom: 50px; }

.advantages-item {
  min-height: 310px; }
  .advantages-item .number {
    color: #F5A100; }
    .advantages-item .number:after {
      content: '';
      display: block;
      width: 45px;
      height: 1px;
      background-color: #7E8888;
      margin-bottom: 20px;
      margin-top: 20px; }
  .advantages-item p {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #191C2B; }

.blue {
  background: #15B0D1; }

.gray {
  background: #B6BDC9; }

.yellow {
  background: #F5A100; }

.gray-2 {
  background: #5E5E63; }

.team-item {
  width: 85%;
  margin-left: auto;
  position: relative;
  margin-bottom: 70px;
  color: #fff;
  padding: 45px 20px 30px; }
  .team-item .content-info {
    width: 75%;
    margin-left: auto; }
    .team-item .content-info .name {
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 20px; }
    .team-item .content-info .position {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 30px; }
  .team-item .photo {
    position: absolute;
    left: -20%;
    top: 50px; }
    .team-item .photo img {
      max-width: 195px; }
    .team-item .photo .links {
      margin-top: 25px;
      text-align: right; }
      .team-item .photo .links a {
        color: #fff;
        margin-right: 15px;
        display: inline-block; }

.open_vacancies {
  padding: 90px 0; }

.job-list {
  margin: 0;
  padding: 0; }
  .job-list li {
    list-style: none;
    font-weight: bold;
    font-size: 24px;
    line-height: 58px;
    text-decoration-line: underline; }

.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg); }

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0); }

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg); }

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0; }

#small-dialog {
  max-width: 600px;
  margin: 0 auto; }
  #small-dialog .bg-blue-contacts {
    padding: 60px 25px 60px; }
  #small-dialog .mfp-close {
    padding: 0;
    top: 5px;
    right: 5px; }
  #small-dialog .submit_btn {
    margin-top: 15px; }

input::placeholder {
  transition: 0.5s;
  /* Время трансформации */ }

input:focus::placeholder {
  font-size: 10px;
  transform: translateY(-20px); }

.bg-blue-contacts {
  background: #1E2132;
  color: #fff;
  padding: 40px 0 60px; }
  .bg-blue-contacts .form_default input, .bg-blue-contacts .form_default textarea, .bg-blue-contacts input[type="file"] + label {
    border-bottom: 2px solid #fff;
    color: #fff;
    /* Firefox 19+ */
    /* Firefox 18- */ }
    .bg-blue-contacts .form_default input::-webkit-input-placeholder, .bg-blue-contacts .form_default textarea::-webkit-input-placeholder, .bg-blue-contacts input[type="file"] + label::-webkit-input-placeholder {
      color: #fff;
      background: #1E2132; }
    .bg-blue-contacts .form_default input::-moz-placeholder, .bg-blue-contacts .form_default textarea::-moz-placeholder, .bg-blue-contacts input[type="file"] + label::-moz-placeholder {
      color: #fff;
      background: #1E2132; }
    .bg-blue-contacts .form_default input:-moz-placeholder, .bg-blue-contacts .form_default textarea:-moz-placeholder, .bg-blue-contacts input[type="file"] + label:-moz-placeholder {
      color: #fff;
      background: #1E2132; }
    .bg-blue-contacts .form_default input:-ms-input-placeholder, .bg-blue-contacts .form_default textarea:-ms-input-placeholder, .bg-blue-contacts input[type="file"] + label:-ms-input-placeholder {
      color: #fff;
      background: #1E2132; }
  .bg-blue-contacts .form-control:focus {
    background-color: #1E2132; }
  .bg-blue-contacts .submit_btn {
    color: #fff; }
  .bg-blue-contacts .h1 {
    font-size: 24px;
    line-height: 30px; }

textarea::placeholder {
  transition: 0.5s;
  /* Время трансформации */ }

textarea:focus::placeholder {
  font-size: 10px;
  transform: translateY(-20px); }

.form-control {
  overflow: visible !important;
  height: auto;
  padding-top: 10px !important; }

textarea.form-control {
  overflow: visible !important;
  height: auto;
  padding-top: 20px !important; }
