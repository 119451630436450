$main-color: #191C2B;

$main-family: 'Open Sans', sans-serif;
$scada-family: 'Scada', sans-serif;

button:focus, button:focus {
  outline: none;
}
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: $main-family;
  font-size: 16px;
  line-height: 29px;
  color: #191C2B;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  &.over_h {
    overflow: hidden;
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $scada-family;
  font-weight: 700;
  width: 100%;
}
.h2 {
  margin-bottom: 30px;
}
header {
  padding: 29px 0 34px;
}
a {
  color: $main-color;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
* {
  outline: none;
}
a:hover {
  text-decoration: none;
  color: #F5A100;
}
.separator {
  width: 100%;
  height: 4px;
  background-color: #F2F6F6;
  margin: 50px 0;
}
header.white {
  .main_menu_block a {
    color: #191C2B;
  }
  position: relative;
}
.lang_block:after{
  border-top-color: $main-color;
}
.main_menu_block a {
  color: $main-color;
}
.main_menu_block ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
     -ms-flex-pack: distribute;
         justify-content: space-around;
}
.main_menu_block a {
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none;
  display: block;

}
.main_menu_block li:hover a {
  animation: menu_hover 250ms linear 0s 1 forwards;
}
.main_menu_block li.active a {
  color: #F5A100;
}
.lang_block {
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  &.open {
    ul {
      display: block;
      position: absolute;
      width: 37px;
      text-align: center;
      border: 1px solid #999;
    }
  }
  ul {
    display: none;
  }
}
.lang_block:after {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  border-top: 8px solid $main-color;
  top: 10px;
  left: 25px;
}
.lang_block.open:after {
  -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
            transform: rotate(-180deg);
  top:5px;
}

.header_scroll_wrapp {
  display: none;
  .scrolled_menu {
    position: fixed;
    top:28px;
    width: 111px;
    background-color: $main-color;
    padding: 10px 20px 12px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    transition: all 0.5s ease-out;
    &.active {
      background-color: #fff;
      .hamburger_inner {
        transform: rotateY(180deg);
        background-color: transparent;
      }
      .hamburger_inner::after {
        transform: translate3d(0,-5px,0) rotate(-45deg);
        background-color: $main-color;
      }
      .hamburger_inner::before {
        transform: translate3d(0,5px,0) rotate(45deg);
        background-color: $main-color;
      }
    }
  }
}
.hamburger {
  float: right;
  background-color: transparent;
  border: none;
  padding: 0;
  .hamburger_wrapp {
    width: 24px;
    height: 12px;
    display: block;
    position: relative;
  }
}
.hamburger_inner, .hamburger_inner::after, .hamburger_inner::before {
  width: 24px;
  display: block;
  height: 2px;
  background-color: #fff;
  position: absolute;
  transition: all 0.5s ease-out;
}
.hamburger_inner::after, .hamburger_inner::before {
  content: '';
}
.hamburger_inner {
  top: 50%;
}
.hamburger_inner::before {
  top: -5px;
}
.hamburger_inner::after {
  bottom: -5px;
}
.full_menu_wrapp {
  transform: translateY(-100%);
  background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
  position:fixed;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  transition: transform .5s ease-in;
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 750px;
  z-index: 9;
  &.open {
    transform: none;

  }
  ul {
    padding: 0;
    list-style: none;
    font-size: 20px;
    line-height: 50px;
  }
  a {
    text-decoration: none;
    color: #fff;
    transition: all 0.25s ease-out;
  }
  li.active a, a:hover {
    color: $main-color;
  }
  .full_menu, .full_title {
    font-family: $scada-family;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    text-transform: uppercase;
    ul {
      font-size: 40px;
    }
  }
}
.full_title:after {
  content: '';
  display: block;
  width: 35px;
  height: 2px;
  background-color: #fff;
  margin: 30px 0;
}
.social_block {
  max-width: 253px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.full_menu li {
  margin-bottom: 20px;
}
.contact_block .fa {
  margin-right: 10px;
}
.full_contacts {
  .contact_block {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 27px;
    span {
      display: block;
    }
  }
}
.full_lang_block {
  font-size: 20px;
  padding-top: 5px;
  a {
    display: inline-block;
    padding: 16px;
    width: 61px;
    text-align: center;
    &.current {
      background-color: #fff;
      border-radius: 50%;
      color: $main-color;
    }
  }
}

.all-select-filter {
  display: flex;
  padding-top: 80px;
  flex-wrap: wrap;
  align-items: flex-start;
  .check-filter {
    box-sizing: border-box;
    border-radius: 28px;
    padding: 12px 45px 12px 35px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    line-height: 180%;
    color: #7E8888;
    border: 1px solid #7E8888;
    position: relative;
    margin: 0 7px;
    margin-bottom: 15px;
    img {
      padding-left: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.filter-portfolio_container {

  transform: translateY(-110%);
  transition: transform 0.5s ease-in-out;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  &.active {
    transform: translateY(0);
  }
  .container {
    position: relative;
    .headline {
      &:after {
        margin: 35px 0;
        content: '';
        display: block;
        width: 35px;
        height: 2px;
        background-color: #191C2B;;
      }
    }
    .close {
      position: fixed;

      top: 50px;
      cursor: pointer;
    }
  }
  .filter-form {

    .filter-submit {
      width: 100%;
      margin-top: 50px;
    }
    display: flex;
    flex-wrap: wrap;
    .type-filter {
      min-width: 350px;
      .title {
        font-weight: bold;
        font-size: 24px;
        font-family: $scada-family;
        line-height: 30px;
        margin-bottom: 30px;
      }
      .check-input {
        input {
          display: none;
          &:checked + label {
            &:before {
              background-color: #F5A100;
            }
            span {
              opacity: 1;
            }
          }
        }
        label {
          padding-left: 30px;
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            position: absolute;
            left: 3px;
            top: 30%;
            display: flex;
            align-items: center;
            color: #fff;
            opacity: 0;
            i {
              font-size: 12px;
            }
          }
          &:before {
            content: '';
            border: 1px solid #F5A100;
            box-sizing: border-box;
            border-radius: 2px;
            display: inline-block;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
          }
        }

      }
    }
  }
}

.full_menu_bottom {
  align-items: center;
}
.content {
  padding: 100px 0;
  .contact_block {
    margin-top: 40px;
    span {
      display: block;
      margin-top: 10px;
    }
    p {
      font-size: 24px;
      font-family: $scada-family;
    }
  }
}
.form_default {
  margin-top: 30px;
  input, textarea {
    background-color: transparent;
    border:none;
    border-radius:0;
    border-bottom: 2px solid $main-color;
    transition: border-color .15s ease-in-out;
    resize: none;
    padding: 6px 0 12px;
    color: $main-color;
    &:focus {
      box-shadow: none;
      border-color: #F5A100;
    }
  }
  input::placeholder, textarea::placeholder {
    color: $main-color;
  }
}
input[type="file"] {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
input[type="file"] + label {
  height: 38px;
  display: block;
  color: $main-color;
  border-bottom: 2px solid $main-color;
  margin: 0;
  cursor: pointer;
  &:hover {
    border-color: #F5A100;
  }
  span {
    display: flex;
    align-items: center;
    width: 100%;
    .fa {
      position: absolute;
      right: 15px;
    }
  }
}
.submit_btn {
  font-family: $scada-family;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin-top: 30px;
  &:focus{
    box-shadow: none;
  }
  &:after {
    content: '';
    width: 41%;
    height: 3px;
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
    display: block;
    transition: width .4s;
  }
  &:hover {
    &:after {
      width: 87%;
    }
    .fa {
      transform: translateX(10px);
    }
  }
  .fa {
    transition: transform .5s;
    transition-delay: .3s;
    padding-left: 5px;
  }
}
.map_block {
  padding-bottom: 523px;
}
footer {
  background: #1E2132;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  a {
    color: #fff;
  }
  .social_block {
    float: right;
  }
  .footer_top_wrapp {
    align-items: center;
  }
}
.footer_top {
  padding: 49px 0 75px;
}
.footer_contacts {
  display: flex;
  justify-content: space-between;
  span {
    display: block;
  }
  .contact_block {
    max-width: 158px;
  }
}
.through_header {
  header {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    z-index: 9;
    a, li {
      color: #fff;
    }
    .lang_block:after {
      border-top-color: #fff;
    }
  }
  .content {
    padding-top: 0;
  }
}
.home_video {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #000;
  z-index: 0;
  video {
    position: absolute;
    min-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    margin: 0 auto;
  }
}
.video_content_block {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
.video_txt {
  font-size: 40px;
  line-height: 50px;
  h1 {
    font-weight: bold;
    font-size: 56px;
    line-height: 62px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
}
.video_contacts {
  position: absolute;
  display: flex;
  bottom: 10%;
  color: #A0A5B8;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px;
  .contact_block span {
    display: inline-block;
    margin-right: 10px;
    .fa {
      margin-right: 4px;
    }
  }
  a {
    color: #A0A5B8;
    &:hover {
      color: #F5A100;
    }
  }
}
.switch_txt {
  opacity: 0;
  position: absolute;
  transition: 0.3s ease-in-out;
  transform: translateY(20%);
  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}
/*Блок с анимацией*/
.home_services {
  padding: 35px 0;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.slick-track {
  display: flex;
  align-items: center;
}
.services_wrapp .slick-slide {
  margin: 0 15px;
}
.services_wrapp .slick-slide:first-child {
  margin-left: 0;
}
.services_static {
  max-width: 460px;
  transition: all 0.5s ease-in;
  .second_title {
    opacity: 1;
    transform: translateY(0);
  }

}



.main-container{ max-width:600px; height: 2000px; margin:0 auto; border:solid 10px green; padding:10px; margin-top:40px; position: relative;}
.main-container *{padding:10px;background:#aaa; border:dashed 5px #000;}
.main-container * + *{margin-top:20px;}
.main-header{
  height:50px; background:#aaa; border-color:red;
}
.main-content{
  min-height:1000px;
}

.main-header{position:-webkit-sticky; position:sticky; top:0;}



.horizontal-scroll {
  position: relative;
}

.horizontal-scroll-counter {
  font-size: 24px;
  line-height: 30px;
  font-family: $scada-family;
  text-align: right;
  color: #F5A100;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
}
.horizontal-scroll-skip {
  position: absolute;
  right: 0;
  bottom: -90px;
  color: #7E8888;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-decoration-line: underline;
}

.services_static {
  overflow: hidden;
  position: relative;
  padding-top: 180px;
  &.active {
    .first_title {
      transform: translateY(-100%);
      opacity: 0;
    }
    .second_title {
      transform: translateY(0);
      display: block;
      opacity: 1;
    }
    p {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  .h2 {
    margin-bottom: 70px;
    position: absolute;
    top: 0;

  }
  .first_title {
    transition: all 0.5s;
  }
  .second_title {
    transform: translateY(-100%);
    opacity: 1;
    transition: all 1s;
  }
  p {
    transition: all 0.3s ease-in-out;
  }
}
.horizontal-scene {
  position: sticky;
  top: 0;

  .container{
    position: relative;
  }
}
.horizontal-scroll-body {
  position: absolute;
  padding-left: 120%;
  bottom: 20px;
  white-space: nowrap;
}
.slick-active .services_static {
  transform: translateY(0);
  opacity: 1;
  transition: all 2s ease-in-out;
  .second_title {
    transform: translateY(-100%);
    opacity: 0;
    transition: all 2s ease-in-out;
  }
}
a.service_item {
  white-space: normal;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  padding: 64px 15px 0;
  width: 460px;
  min-height: 350px;
  color: $main-color;
  background-color: #F2F6F6;
  overflow: hidden;
  position: relative;
  &.active {
    .hover-bg {
      transform: translateY(0);
    }
    color: #fff;
  }
  .hover-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    transform: translateY(101%);
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 1;
    li {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  div {
    font-family: $scada-family;
    font-weight: 700;
    font-size: 30px;
    margin: 15px 0;
    position: relative;
    z-index: 1;
  }
  p {
    z-index: 1;
  }
}
.slick-active + .slick-active a.service_item {
  background-color: #F5A100;
  color: #fff;
  div {
    margin-top: 0;
  }
}
/*Блок с анимацией конец*/
.home_offers {
  padding: 35px 0;
}
.offer_item {
  position: relative;
  margin: 15px 0;
  width: 50%;
  padding-right: 15px;
  max-height: 217px;
  overflow: hidden;
  &:nth-child(even) {
    padding-left: 15px;
  }
  &.white {
    color: #fff;
  }
  &.last {
    width: 100%;
    padding-left: 15px;
    padding-right: 0;
    max-height: 464px;
    min-height:404px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  div {
    position: absolute;
    top: 32px;
    left: 40px;
    font-size: 20px;
    line-height: 27px;
    max-width: 260px;
  }
}
.global_partners {
  padding: 35px 0;
}
.partners_wrapp {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
}
.partner_item {
  margin: 10px;
  width: 14%;
  text-align: center;
  img {
    max-width: 70%;
  }

}
.show_more_link a {
  font-size: 20px;
  text-decoration: underline;
  font-weight: 700;
  line-height: 25px;
  color: #7E8888;
  &:hover {
    text-decoration: none;
    color: #F5A100;;
  }
}
.global_portfolio {
  padding: 35px 0;
}
.global_portfolio .container {
  padding: 0;
}
.portfolieo_item {
  position: relative;
  overflow: hidden;
  margin: 27px 0;
  transition: all .4s;
  .overlay {
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(240, 244, 250, 0.57) 0%, rgba(240, 244, 250, 0.7) 68.75%, rgba(255, 255, 255, 0) 100%);
  }
  a {
    display: block;
  }
  img {
    width: 100%;
    transition: all .6s ease-in-out;
  }
  a {
    z-index: 9;
  }
  span {
    display: block;
  }
  .tags_block {
    transform: translateY(100%);
    opacity: 0;
    transition: all .5s ease-in;
    display: flex;
  }
  &:hover .tags_block {
    transform: translateY(0);
    opacity: 1;
  }
  &:hover .portfolio_txt {
    top: 30px;
  }
  &:hover img {
    transform: scale(1.1);
  }
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}
.portfolio_txt {
  position: absolute;
  width: 100%;
  top: 60px;
  text-align: center;
  z-index: 2;
  text-transform: uppercase;
  transition: all .5s ease-in;
  > span {
    color: #7E8888;
    font-size: 12px;
    font-weight: 600;
  }
  .title {
    font-family: $scada-family;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #000;
  }
}
.tags_block {
  display: flex;
  justify-content: center;
  max-width: 70%;
  margin: 10px auto 0;
  flex-wrap: wrap;
  span {
    display: block;
    border: 1px solid #000;
    border-radius: 16px;
    padding: 0 25px;
    box-sizing: border-box;
    margin: 10px 5px;
    color: #191C2B;
    font-size: 11px;
    font-weight: 600;
  }
}
.portfolieo_item.last_link {
  height: 100%;
  max-height: 589px;
  display: flex;
  align-items: center;
  border: 3px solid #ADC8DB;
  .portfolio_txt {
    position: static;
  }
  div {
    margin-top: 35px;
    font-size: 12px;
    font-weight: 600;
  }
  a {
    text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  }
}
.home_quote {
  padding: 130px 0;
}
.quote_left {
  position: relative;
}
.quote_icon {
  position: absolute;
  left: -30px;
  top: -30px;
  z-index: -9;
}
.quote_img {
    display: flex;
    margin-bottom: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    margin-top: 50px;
    img {
      width: 80px;
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 50%;
      width: 104px;
    }
    div + div {
      padding-left: 15px;
    }
}
.quote_right {
  max-height: 340px;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 6px; height: 3px; padding: 0 2px;}
  &::-webkit-scrollbar-button { display: none;}
  &::-webkit-scrollbar-track { width: 1px; background-color: #887D6B;}
  &::-webkit-scrollbar-track-piece {background: linear-gradient(to right, #fff 30%, #887D6B 50%, #fff 70%);}
  &::-webkit-scrollbar-thumb {width: 6px; height: 50px; background-color: #887D6B;}
}
.footer_form {
  padding: 125px 0;
  input, textarea {
    border-bottom: 2px solid #fff;
    transition: border-color .15s ease-in-out;
    padding: 6px 0 12px;
    color: #fff;
    &:focus, :active {
      box-shadow: none;
      border-color: #F5A100;
      background-color: transparent;
      color: #fff;
    }
  }
  input::placeholder, textarea::placeholder {
    color: #fff;
  }
  input[type="file"] + label {
  height: 38px;
  display: block;
  color: #fff;
  border-bottom: 2px solid #fff;
}
.submit_btn {
  color: #fff;
  }
  .form_default {
    margin-top: 0;
  }
}
@keyframes menu_hover {
0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
50% {
    -webkit-transform: translateY(.938rem);
    transform: translateY(.938rem);
    opacity: 0;
}
51% {
    -webkit-transform: translateY(-.938rem);
    transform: translateY(-.938rem);
    opacity: 0;
}
100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    color: #F5A100;
}
}


.portfolio_header {
  padding-top: 108px;
  padding-bottom: 20px;
}


.first-block_text {
  .pre-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 180%;
    text-transform: uppercase;
    padding-left: 4px;
  }
  .title {
    font-weight: bold;
    font-size: 56px;
    line-height: 95.92%;
    text-transform: uppercase;
    font-family: $scada-family;
    margin-bottom: 70px;
  }
  p {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    font-family: $scada-family;
    color: #7E8888;
    margin-bottom: 20px;
  }
  .yellow-btn {
    display: inline-block;
  }
}

.yellow-btn {
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 11px;
  line-height: 180%;
  background: #F5A100;
  border: 1px solid #F5A100;
  box-sizing: border-box;
  border-radius: 28px;
  padding: 12px 35px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #1E2132;
    color: #fff;
    border: 1px solid #1E2132;
  }
}

.breadcrumbs {
  ul {
    margin: 20px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 35px;
      list-style: none;
      position: relative;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      font-family: $main-family;
      color: #7E8888;
      &:last-child {
        opacity: 0.6;
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        width: 13px;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        right: -30px;
        display: inline-block;
        height: 4px;
        background-image: url(../images/breadarr.svg);
        background-size: cover;
      }
    }
  }
}

 .service_page {
   padding-top: 70px;
   padding-bottom: 130px;
 }

.service_row {
  margin-top: 50px;
}

.service_page {
  .service_item {
    margin-bottom: 38px;
    background: #F2F6F6;
    padding: 20px 50px 50px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    height: 288px;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    &:hover {
      div {
        color: #fff;
        img {
          transform: translate(0);
          bottom: unset;
          opacity: 1;
        }
      }
      span {
        transform: translateY(0);
      }
      p {
        color: #fff;
      }
    }
    span {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transition: all 0.3s ease-in-out;
      transform: translateY(100%);
      background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
    }
    div {
      font-weight: bold;
      font-size: 24px;
      z-index: 1;
      line-height: 30px;
      font-family: $scada-family;
      margin-bottom: 15px;
      position: relative;
      transition: all 0.3s ease-in-out;
      img {
        position: absolute;
        right: 0px;
        transform: translate(-20px,20px);
        top: 10px;
        opacity: 0;
        transition: all 0.4s ease-in-out;
      }
    }
    p {
      position: relative;
      z-index: 1;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: #191C2B;
    }
  }
}


.headline {
  font-family: $scada-family;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #191C2B;
  margin-bottom: 35px;
}
.image-block1 {
  height: 270px;
  margin-bottom: 30px;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.content-info,.portfolio-project_container {

  ul {
    padding-left: 18px;
    li {
      color: #F5A100;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      span {
        color: #191C2B;
      }
    }
  }
  h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    font-family: $scada-family;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .border-top {
    padding-top: 20px;
    border-top: 1px solid rgba(51, 51, 51, 0.3);
  }
  .two-col-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(51, 51, 51, 0.3);
    &.no-border {
      border-top: 0;
    }
    div {
      width: 22%;
      padding-right: 15px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
    p {
      width: 75%;
      margin-bottom: 0;
    }
  }
}

.capture-block {
  background: #F2F6F6;
  min-height: 235px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  div {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 28px;
  }
  .yellow-btn {
    padding: 12px 55px;
  }
}

.yellow-number {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #F5A100;
  padding-right: 10px;
}

.quote {
  padding-left: 60px;
  margin: 50px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #191C2B;
  font-family: $scada-family;
  position: relative;
  padding-top: 25px;
  img {
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: -1;
  }
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #7E8888;
    display: block;
    margin-top: 15px;
    font-family: $main-family;
  }
}


.news-item {
  margin-bottom: 50px;
  .news-img {
    text-align: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 110%;
    font-family: $scada-family;
    color: #333;
    margin: 15px 0 20px;
  }

  p {
    font-size: 16px;
    line-height: 180%;
    color: #4B4B4B;
  }
}
.mr-20 {
  margin: 20px 0;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  //height: 102px;
  align-items: flex-start;
  overflow: hidden;
  span {
    display: block;
    border: 1px solid #666;
    border-radius: 16px;
    padding: 0 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10px 0;
    color: #666;
    font-size: 11px;
    font-weight: 600;
    margin-right: 10px;
  }
}
.horizontal-news {
  &.left {
    .news-img {
      float: left;
      margin-right: 30px;
    }

  }
  &.right {
    .news-img {
      margin-left: 30px;
      float: right;
    }
  }
  .news-img {
    width: 65%;
    height: 425px;

  }
  .tags {
    margin-bottom: 20px;
  }
}

.pagination-block {
  text-align: center;
  .prev,.next {
    font-size: 14px;
  }
  ul {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    li {
      list-style: none;
      margin: 0 25px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-transform: uppercase;
    }
  }
}
.pb-30 {
  padding-bottom: 30px;
}
.more-btn {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  font-family: $scada-family;
  color: #191C2B;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    background: linear-gradient(90deg, #F58220 0%, #FAA61A 99.9%);
    display: inline-block;
    height: 3px;
    width: 50%;
    transition: width 0.3s ease-in-out;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }

}

.message-btn {
  position: fixed;
  right: 30px;
  bottom: 50px;
  .start-btn {
    width: 56px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #9EDF49;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      display: none;
    }
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    &:after {
      content: '';
      position: absolute;
      border: 1px solid #9EDF49;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: widgetPulse infinite 1.5s;
    }
  }
  .green-circle {
    width: 56px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #9EDF49;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
  }
  &.active {
    .circle1 {
      top: -80px;
    }
    .circle2 {
      top: -160px;
    }
    .start-btn {
      background: #F2F6F6;
      transform: rotate(180deg);
      &>img {
        display: none;
      }
      span {
        display: block;
      }
      &:after {
        display: none;
        animation: none;
      }
    }
  }
}

@keyframes widgetPulse {
  50% {
    transform: scale(1,1);
    opacity: 1;
  }
  100% {
    transform: scale(2,2);
    opacity: 0;
  }
}


.duo-photo {
  margin-bottom: 30px;
  img {
    width: 100%;
    height: 300px;
  }
  p {
    margin-top: 1px;
    font-weight: 600;
    font-size: 16px;
    line-height: 170.23%;
  }
}

.final-news {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 170.23%;
  letter-spacing: 0.05em;
  color: #666666;
}
.separator-2 {
  background: #F2F6F6;
  height: 4px;
  margin-bottom: 40px;
}

.portfolio-poster_top {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #191C2B;
    opacity: 0.6;
    z-index: 1;
  }
  .container {
    height: 100vh;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    color: #fff;
    .tags  span{
      color: #fff;
      border: 1px solid #fff;
    }
  }
}

.portfolio-project_container {
  padding: 100px 0;
}

.video-poster {
  margin: 50px 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  a {
    width: 270px;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
    img {
      width: 100%;
    }
  }
}

.pair-photo {
  text-align: right;
  position: relative;
  margin-bottom: 60px;
  .small {
    position: absolute;
    top: 15%;
    left: 0;
    z-index: 10;
  }
  .big {
    position: relative;
    z-index: 9;
  }
}

.simple-img-wrapper {
  margin-bottom: 30px;
  img {
    width: 100%;
  }
}
.portfolio_slider {
  margin-bottom:80px;
}
.multiple-items {
  background: #F2F6F6;
  padding: 40px 0;
  .slide-item {
    &.slick-active {
      opacity: 1;
      transform: scale(1);

    }
    transform: scale(0.95);
    opacity: 0.2;
    padding: 0 15px;
    transition: all 0.3s ease-in-out;
    img {
      width: 100%;
    }
  }

}
.navigation-slider-port {
  position: relative;
  display: flex;
  width: 50%;
  top: -18px;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;
  .counter-slide {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    .current-slide {
      font-family: $scada-family;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #191C2B;
    }
    .all-slide {
      padding-right: 30px;
    }
  }
}
#portfolioNav {
  position: relative;
  display: flex;
  padding: 0 15px;


  .slick-arrow {
    width: 170px;
    height: 125px;
    margin-right: 12px;
   padding: 0;
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0;
    background: #191C2B;
    border: none;
  }
}

.portfolio-footer-nav {
  background-image: url("../images/bg-portff.png");
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &>div {
      width: 40%;
      &.center-nav {
        width: 20%;
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          opacity: 0.3;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: 1;
            color: #191C2B;
          }
          img {
            padding-bottom: 8px;
          }
        }
      }
    }
  }
  .skip-project {
    &.left {
      &:hover {
        img {
          transform: translateX(-10px);
        }
      }
      img {
        transition: transform 0.2s ease-in-out;
        padding-right: 30px;
      }
    }
    &.right {
      display: flex;
      justify-content: flex-end;
      &:hover {
        img {
          transition: transform 0.2s ease-in-out;
          transform: translateX(10px);
        }
      }
      text-align: right;
      img {
        padding-left: 30px;
      }
    }
    a {
      display: flex;
      align-items: center;
      .nav-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 180%;
        color: #7E8888;
        span {
          display: block;
          margin-top: 8px;
          color: #191C2B;
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          font-family: $scada-family;
        }
      }
    }
  }
}


.error404 {
  text-align: center;
  min-height: 70vh;
  .title {
    font-weight: bold;
    font-size: 56px;
    line-height: 95.92%;
    font-family: $scada-family;
    text-transform: uppercase;
    color: #191C2B;
    margin-bottom: 35px;
  }
  p {
    a {
      background-color: rgba(245,161,0,0.2);
      display: inline-block;
      text-decoration: underline;
    }
  }
}

.capture_form {
  .footer_form .submit_btn {
    color: #191C2B;
    i {
      color: #191C2B;
    }
  }
  .footer_form input, .footer_form textarea {
    color: #191C2B;
    border-bottom: 2px solid #191C2B;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #191C2B;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #191C2B;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #191C2B;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #191C2B;
    }
  }
}

.communication_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  .social {
    a {
      display: inline-block;
      margin-right: 15px;
    }
  }
}



.our_values {
  .services_static {
    padding-top: 110px;
  }
  a.service_item {
    width: 360px;
    min-height: 290px;
    height: 290px;
    overflow: hidden;
    position: relative;
    padding: 0;
    &.active {
      .open-photo {
        opacity: 1;
      }
    }
  .open-photo {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0;
    transition: opacity 0.3s;
  }
    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.history_company {
  .services_static {
    padding-top: 140px;
  }
  a.service_item {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 64px 15px 0 64px;
  }
}

.about-header-scroll {
  .first-block_text {
    max-width: 58%;
    overflow: hidden;
    position: relative;
    &.active {
      .title,.pre-title {
        transform: translateY(-150%);
        transition: transform 1s;
      }
      p {
        transform: translateX(-150%);
        transition: transform 0.7s;
      }
    }
  }
  a.service_item {
    height: 700px;
    width: auto;
    background-color: inherit;
  }
  .horizontal-scroll-body {
    padding-left: 58%;
    top: -70%;
  }
}

.job-list_header {
  padding: 70px 0 60px;
}
.mb-50 {
  margin-bottom: 50px;
}
.achievements {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 80px;
  .achievements-item {
    cursor: pointer;
    padding:54px 0 ;
    width: 28%;
    text-align: center;
    margin-bottom: 20px;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background: #F2F6F6;
    }
    img {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 35px;
    }
    p {
      color: #000;
    }
  }
}

.team-section {
  padding-bottom: 50px;
}
.advantages-item {
  min-height: 310px;
  .number {
    color: #F5A100;

    &:after {
      content: '';
      display: block;
      width: 45px;
      height: 1px;
      background-color: #7E8888;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #191C2B;
  }
}

.blue {
  background: #15B0D1;
}
.gray {
  background: #B6BDC9;
}
.yellow {
  background: #F5A100;
}
.gray-2 {
  background: #5E5E63;
}
.team-item {
  width: 85%;
  margin-left: auto;
  position: relative;
  margin-bottom: 70px;
  color: #fff;
  padding: 45px 20px 30px;
  .content-info {
    width: 75%;
    margin-left: auto;
    .name {
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 20px;
    }
    .position {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 30px;
    }
    p {

    }
  }
  .photo {
    position: absolute;
    left: -20%;
    top: 50px;
    img {
      max-width: 195px;
    }
    .links {
      margin-top: 25px;
      text-align: right;
      a {
        color: #fff;
        margin-right: 15px;
        display: inline-block;
      }
    }
  }
}


.open_vacancies {
  padding: 90px 0;
}

.job-list {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    font-weight: bold;
    font-size: 24px;
    line-height: 58px;
    text-decoration-line: underline;
  }
}

.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  -webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
  -moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
  -ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
  -o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
  transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );

}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
  -moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
  -ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
  -o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
  transform: translateY(0) perspective( 600px ) rotateX( 0 );
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;

  -webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
  -moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
  -ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
  -o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
  transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;

  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

#small-dialog {
  max-width: 600px;
  margin:  0 auto;
  .bg-blue-contacts {
    padding: 60px 25px 60px;
  }
  .mfp-close {
    padding: 0;
    top: 5px;
    right: 5px;
  }
  .submit_btn {
    margin-top: 15px;
  }
}

input::placeholder {
  transition: 0.5s; /* Время трансформации */
}
input:focus::placeholder {
  font-size: 10px;
  transform: translateY(-20px);
}
.bg-blue-contacts {
  background: #1E2132;
  color: #fff;
  padding: 40px 0 60px;
  .form_default input, .form_default textarea,input[type="file"] + label {
    border-bottom: 2px solid #fff;
    color: #fff;
    &::-webkit-input-placeholder {color:#fff;background:#1E2132; }
    &::-moz-placeholder          {color:#fff;background:#1E2132;}/* Firefox 19+ */
    &:-moz-placeholder           {color:#fff;background:#1E2132;}/* Firefox 18- */
    &:-ms-input-placeholder      {color:#fff;background:#1E2132;}
  }
  .form-control:focus {
    background-color: #1E2132;
  }
  .submit_btn {
    color: #fff;
  }
  .h1 {
    font-size: 24px;
    line-height: 30px;

  }
}
textarea::placeholder {
  transition: 0.5s; /* Время трансформации */
}
textarea:focus::placeholder {
  font-size: 10px;
  transform: translateY(-20px);
}
.form-control {
  overflow: visible!important;
  height: auto;
  padding-top: 10px!important;
}
textarea.form-control {
  overflow: visible!important;
  height: auto;
  padding-top: 20px!important;
}
